import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { ISharedState, SharedDialogModule } from '@safarilaw-webapp/shared/dialog';
import { HotkeyModule } from 'angular2-hotkeys';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { SafariRootAppComponent } from './app/app.component';
import { HeaderComponent } from './header/components/header/header.component';
import { NavbarRenderedDirective } from './navbar/components/navbar/navbar-rendered.directive';
import { NavbarComponent } from './navbar/components/navbar/navbar.component';

import { FeatureCoManageDataAccessModule } from '@safarilaw-webapp/feature/co-manage/data-access';
import { SharedRoutingUtilityModule } from '@safarilaw-webapp/shared/routing-utility';
import { AppReducerService } from './state/state.reducer';
const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<ISharedState>>('App State Reducers');

@NgModule({
  declarations: [],
  exports: [HeaderComponent, SafariRootAppComponent, NavbarComponent],
  imports: [
    SafariRootAppComponent,
    HeaderComponent,
    NavbarComponent,
    NavbarRenderedDirective,
    ToastrModule.forRoot(),
    HotkeyModule.forRoot(),
    SharedDialogModule,
    SharedRoutingUtilityModule,
    BrowserAnimationsModule,
    // We need this for header, navbar and navbar service which are located
    // here.
    FeatureCoManageDataAccessModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true
        }
      }
    ),
    EffectsModule.forRoot([]),
    StoreModule.forFeature('appState', REDUCER_TOKEN),
    CommonModule,
    RouterModule
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      deps: [AppReducerService],
      useFactory: (service: AppReducerService) => service.reducers
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class SharedAppBootstrapModule {}
