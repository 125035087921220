import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CustomThemeIds } from '@safarilaw-webapp/feature/rms/data-access';
import { MaintenanceInfo } from '@safarilaw-webapp/shared/app-bootstrap';

@Component({
  selector: 'sl-rms-maintenance-splash',
  templateUrl: './maintenance-splash.html',
  styleUrls: ['./maintenance-splash.scss'],
  imports: [NgClass]
})
export class MaintenanceSplashComponent {
  @Input()
  maintenanceInfo: MaintenanceInfo = undefined;

  get title() {
    return this.maintenanceInfo?.splash?.title || 'WEBSITE MAINTENANCE IN PROGRESS';
  }
  get details() {
    return this.maintenanceInfo?.splash?.details || 'ServePort is undergoing scheduled maintenance. Please check back later.';
  }

  get customThemeId(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Window global for now
    return window['Safari']?.customThemeId as string;
  }

  get customThemeCss(): string {
    if (this.customThemeId == CustomThemeIds.walmart) {
      return CustomThemeIds.walmart;
    }
    return '';
  }

  get customThemeBannerCss(): string[] {
    if (this.customThemeId == CustomThemeIds.walmart) {
      return ['py-4', 'alert-light'];
    }
    return [];
  }
}
